import React, { useState, useEffect } from 'react';
import InterestButton from './InterestButton';
import Algolia from '../../../search/services/Algolia';
import { useSelector } from 'react-redux';
import { hasKeywords } from '../../helpers/search';

const InterestFilter = () => {
  const [interests, setInterests] = useState([]);
  const [availableFilters, setAvailableFilters] = useState(interests);
  const search = useSelector(state => state.search);
  const results = useSelector(state => state.results.hits);

  useEffect(() => {
    getInterests()
  }, [])

  /**
   * Handle available interests.
   */
  useEffect(() => {
    if (results.length && hasKeywords(search)) {
      const filters = results.reduce((acc, result) => {
        if ('interestSegments' in result && Array.isArray(result.interestSegments)) {
          result.interestSegments.forEach(interest => {
            if (acc.indexOf(interest) === -1) {
              acc.push(interest)
            }
          })
        }

        return acc
      }, [])

      setAvailableFilters(filters)
    } else {
      setAvailableFilters(interests);
    }
  }, [results, interests])

  const getInterests = async () => {
    const facets = await Algolia.getFacets('interestSegments')
    setInterests(facets.map(facet => facet.value))
  }

  const isActive = (interest) => (
    search.interests.find(filter => filter === interest) !== undefined
  )

  return (
    <div className="crtSearchFilter crtInterestFilter">
      {
        interests.map(interest => (
          <InterestButton
            key={interest}
            value={interest}
            isActive={isActive(interest)}
            disabled={!isActive(interest) && !availableFilters.includes(interest)}
          />
        ))
      }
    </div>
  );
};

export default InterestFilter;
