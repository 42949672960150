import {
  formatAlgoliaFacets,
  formatAlgoliaFilters
} from '../helpers/filters';
import Algolia from '../../search/services/Algolia';

export const SET_RESULTS = 'SET_RESULTS';
export const RESET_RESULTS = 'RESET_RESULTS';

export const getResults = () => async (dispatch, getState) => {
  const search = getState().search;
  const facetFilters = formatAlgoliaFacets(search);
  const filters = formatAlgoliaFilters(search);

  const results = await Algolia.search(search.query,
    {
      facetFilters,
      filters,
      page: search.currentPage,
    },
  );

  if (results) {
    dispatch({ type: SET_RESULTS, payload: results });
  }
}

export const resetResults = () => (dispatch) => {
  dispatch({ type: RESET_RESULTS })
}
