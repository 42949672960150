import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchInput from './SearchInput';
import InterestFilter from './InterestFilter';
import AudienceFilter from './AudienceFilter';
import SeasonFilter from './SeasonFilter';
import ResetFiltersButton from '../../../search/components/ResetFiltersButton';
import Translation  from '../../../common/Translation';
import { resetFilters } from '../../actions/searchAction';
import Icon from '../../../common/Icon';
import ResultTitleCount from '../../../search/components/Results/ResultTitleCount';

const Form = () => {
  const [mobileDisplayFilters, setMobileDisplayFilters] = useState(false);
  const { search, results } = useSelector(state => state);
  const dispatch = useDispatch()
  const inputRef = useRef();

  const getNumberOfFilters = () => {
    const allFilters = [
      ...search.interests,
      search.audience,
      search.period.startDate,
      search.period.endDate,
    ]
    const activeFilters = allFilters.filter(f => f !== null && f !== undefined)

    return activeFilters.length;
  }

  const onSubmit = (event) => {
    if (inputRef) {
      inputRef.current.blur();
    }

    event.preventDefault()
  }

  return (
    <form className="crtSearchAdvanced-form" onSubmit={onSubmit}>
      <SearchInput inputRef={inputRef} />

      <div className="crtSearchAdvanced-mobile-filters hideOnDesktop">
        <ResultTitleCount results={results} classes="crtSearchAdvanced-results-title" />

        <button
          className="crtMobileResetFiltersActived crtBtn"
          type="button"
          role="button"
          onClick={() => setMobileDisplayFilters(oldState => !oldState)}
        >
          <Translation id="filters" /> {getNumberOfFilters() > 0 && `(${getNumberOfFilters()})` }
          <Icon
            name="down"
            className={`${mobileDisplayFilters ? 'icon-up' : 'icon-down'}`}
          />
        </button>
      </div>

      <div className={`crtSearchAdvanced-filters ${mobileDisplayFilters ? 'displayOnMobile' : 'hideOnMobile'}`}>
        <InterestFilter />

        <div className="crtSearchAdvanced-filters-row">
          <AudienceFilter />
          <SeasonFilter />
          <ResetFiltersButton action={() => dispatch(resetFilters())} />
        </div>
      </div>
    </form>
  );
};

export default Form;
