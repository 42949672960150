import React, { useContext } from 'react';
import { TranslationContext } from '../../../common/Translation';

const ResultTitleCount = ({ results, classes }) => {
  const { translationRegistry: translations } = useContext(TranslationContext)

  return (
    <h2 className={classes}>
      {results.nbHits > 0
      ? `${results.nbHits} ${results.nbHits > 1 ? translations.results : translations.result}`
      : null }
    </h2>
  );
};

export default ResultTitleCount;
