/**
 * Format facets (segments) for Algolia search filters.
 *
 * @param search
 */
export const formatAlgoliaFacets = (search) => {
  const facets = [];

  if (search.interests && search.interests.length > 0) {
    facets.push(search.interests.map(interest => `interestSegments:${interest}`))
  }
  if (search.audience) {
    facets.push(`audienceSegments:${search.audience.value}`)
  }

  return facets;
}

/**
 * Format filters for Algolia search.
 *
 * @param search
 * @returns string[]
 */
export const formatAlgoliaFilters = (search) => {
  const filters = [];

  if (search.period.startDate && search.period.endDate) {
    filters.push(`openingPeriods.start <= ${search.period.startDate.toSeconds()}`);
  }

  if (search.period.endDate) {
    filters.push(`openingPeriods.end >= ${search.period.endDate.toSeconds()}`);
  }

  return filters.length ? filters.join(' AND ') : undefined;
}
