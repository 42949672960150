import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getResults, resetResults } from '../actions/resultsAction';
import Pager from '../../search/components/Results/Pager';
import ResultsList from '../../search/components/Results/ResultsList';
import { resetFilters, setPage } from '../actions/searchAction';
import ResultTitle from '../../search/components/Results/ResultTitle';
import { hasFilter, hasKeywords, hasNoSearch } from '../helpers/search';

const Results = () => {
  const search = useSelector(state => state.search);
  const results = useSelector(state => state.results);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasKeywords(search) || hasFilter(search)) {
      dispatch(getResults())
    } else if (hasNoSearch(search)) {
      dispatch(resetResults())
    }
  }, [search]);

  return (
    <>
      <div className="crtSearchAdvanced-results">
        <ResultTitle results={results} type="advanced" searchQuery={search.query} />
      </div>

      {results.hits &&
        <>
          <ResultsList results={results} />

          <Pager
            page={results.page}
            nbPages={results.nbPages}
            onPageChange={(newPage) => dispatch(setPage(newPage))}
          />
        </>
      }
    </>
  );
};

export default Results;
