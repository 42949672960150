import React from 'react';
import Translation from '../../common/Translation';

const ResetFiltersButton = ({ action }) => (
  <div className="crtResetFilters">
    <button
      type="button"
      role="button"
      tabIndex="0"
      aria-pressed={false}
      onClick={action}
    >
      <Translation id="reset_filters" />
    </button>
  </div>
);

export default ResetFiltersButton;
