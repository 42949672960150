import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setQuery } from '../../actions/searchAction';
import { TranslationContext } from '../../../common/Translation';

const SearchInput = ({ inputRef }) => {
  const { query } = useSelector(state => state.search)
  const dispatch = useDispatch();
  const { translationRegistry: translations } = useContext(TranslationContext)

  const onChange = (value) => {
    dispatch(setQuery(value))
    dispatch(setPage(0))
  }

  return (
    <div className="crtInner">
      <div className="crtSearchAdvanced-wrp">
        <input
          ref={inputRef}
          type="text"
          placeholder={translations.search_input_placeholder}
          className="crtSearchAdvanced-input"
          autoFocus
          onChange={event => onChange(event.target.value)}
          value={query}
        />
      </div>
    </div>
  );
};

export default SearchInput;
