export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const PREVIOUS_MONTH = 'PREVIOUS_MONTH';
export const NEXT_MONTH = 'NEXT_MONTH';

export const reducer = (state = { selectedDate: value }, action) => {
  switch (action.type) {
    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.date
      }
    case PREVIOUS_MONTH: {
      let month = 0;
      let year = 0;
      if (state.month === 0) {
        month = 11;
        year = state.year - 1;
      } else {
        month = state.month - 1;
        year = state.year;
      }
      return {
        ...state,
        month,
        year
      }
    }
    case NEXT_MONTH: {
      let month = 0;
      let year = 0;
      if (state.month === 11) {
        month = 0;
        year = state.year + 1;
      } else {
        month = state.month + 1;
        year = state.year;
      }
      return {
        ...state,
        month,
        year
      }
    }
    default:
      return state;
  }
}
