import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import { Provider } from 'react-redux';
import { TranslationContext } from '../common/Translation';
import Advanced from './components/Advanced';

const { searchData: { locale = 'fr', translations = {} } = {} } = window
const searchAdvancedElement = document.getElementById('search-advanced')

if (searchAdvancedElement) {
  ReactDOM.render(
    <Provider store={store}>
      <TranslationContext.Provider value={{locale, translationRegistry: translations}}>
        <Advanced />
      </TranslationContext.Provider>
    </Provider>,
    searchAdvancedElement
  )
}
