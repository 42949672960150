import {RESET_FILTERS, RESET_SEARCH, SET_AUDIENCE, SET_INTERESTS, SET_PAGE, SET_QUERY, SET_SEASON} from '../actions/searchAction';

const initialState = {
  interests: [],
  audience: null,
  period: {
    season: null,
    startDate: null,
    endDate: null,
  },
  query: '',
  currentPage: 0,
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INTERESTS:
      return { ...state, interests: payload };
    case SET_AUDIENCE:
      return { ...state, audience: payload}
    case SET_SEASON:
      return { ...state, period: payload}
    case RESET_FILTERS:
      return {
        ...initialState,
        query: state.query,
      }
    case RESET_SEARCH:
      return initialState
    case SET_QUERY:
      return { ...state, query: payload }
    case SET_PAGE:
      return { ...state, currentPage: payload }
    default:
      return state;
  }
}

export default searchReducer;
