import React, { useReducer, useEffect, useState, useContext, useRef } from 'react';
import jsCalendar from 'js-calendar'
import { DateTime } from 'luxon'
import Translation, { TranslationContext } from '../../common/Translation';
import Info from 'luxon/src/info';
import { NEXT_MONTH, PREVIOUS_MONTH, reducer, SET_OPENING_DATE, SET_SELECTED_DATE } from '../reducers/calendarReducer';
import { CalendarsContext } from './Form/SeasonFilter';
import Icon from '../../common/Icon';

const Calendar = ({ id, value, handleChange, openingDate}) => {
  const [months, setMonths] = useState([]);
  const [openings, setOpenings] = useState([]);
  const [state, dispatch] = useReducer(reducer, {
    selectedDate: JSON.stringify(value) || null,
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  });
  const { locale } = useContext(TranslationContext)
  const [openCalendar, setOpenCalendar] = useContext(CalendarsContext)
  const calendarElement = useRef();

  useEffect(() => {
    setMonths([...Array(12).keys()].map(month => (
      Info.months('long', { outputCalendar: locale })[month]
    )))

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    if(openingDate) {
      setOpenings(
        openingDate.map(period => {
          const end = new Date(period.endDate);
          end.setHours(23);
          end.setMinutes(59);
          return {
            start: new Date(period.startDate).getTime(),
            end: end.getTime()
          }
        })
      )
    }
  }, [openingDate])

  useEffect(() => {
    if (value) {
      dispatch({
        type: SET_SELECTED_DATE,
        date: JSON.stringify(value),
      });
      setOpenCalendar(null)
    } else {
      dispatch({
        type: SET_SELECTED_DATE,
        date: null,
      });
    }
  }, [value])

  const handleClickOutside = (event) => {
    if (calendarElement.current && !calendarElement.current.contains(event.target)) {
      setOpenCalendar(null);
    }
  }

  const isAvailable = (dateObject) => {
    const date = dateObject.getTime();

    return openings.some(period => {
      return date >= period.start && date <= period.end;
    });
  }

  const getLocalMonth = (month) => {
    const date = DateTime.fromFormat(month, 'cccc');
    const shortMonth = date.setLocale(locale).toFormat('EEE');
    const cleanMonth = shortMonth.replace('.', '');

    return cleanMonth.charAt(0).toUpperCase() + cleanMonth.slice(1);
  }

  const buildCalendar = () => {
    const generator = jsCalendar.Generator({ daysOnly: true });
    return generator(state.year, state.month, jsCalendar.addLabels).cells;
  }

  const selectDate = date => {
    dispatch({
      type: SET_SELECTED_DATE,
      date: JSON.stringify(date)
    });
    setOpenCalendar(null)
    handleChange(date)
  }

  const buildCalendarMarkup = () => {
    const calendar = buildCalendar();
    const weeks = [];

    calendar
      .filter(item => ["prevMonth", "monthDay", "nextMonth"].includes(item.type))
      .forEach((day, index) => {
        if (index % 7 === 0) {
          weeks.push([day]);
        } else {
          weeks[weeks.length - 1].push(day);
        }
      });

    return (
      <div className="crtCalendar" ref={calendarElement}>
        <div className="crtCalendar-head">
          <button className="crtCalendar-monthBtn" onClick={() => dispatch({ type: PREVIOUS_MONTH })} type="button">
            <svg viewBox="0 0 100 100" className="crt-icon-left" aria-hidden="true">
              <use xlinkHref="#crt-left" />
            </svg>
            <span className="sr-only"><Translation id="calendar-previous-btn" /></span>
          </button>

          {months[state.month]} {state.year}

          <button className="crtCalendar-monthBtn" onClick={() => dispatch({ type: NEXT_MONTH })} type="button">
            <svg viewBox="0 0 100 100" className="crt-icon-right" aria-hidden="true">
              <use xlinkHref="#crt-right" />
            </svg>
            <span className="sr-only"><Translation id="calendar-next-btn" /></span>
          </button>
        </div>

        <div className="crtCalendarTable-wpr">
          <table className="crtCalendarTable">
            <thead className="crtCalendarTable-head">
            <tr className="crtCalendarTable-row">
              {
                calendar
                  .filter(item => item.type === "dayLabel")
                  .map((label, index) => (
                    <th key={index} className="crtCalendarTable-cell">
                      {/*<Translation id={label.desc} />*/}
                      {getLocalMonth(label.desc)}
                    </th>)
                  )
              }
            </tr>
            </thead>

            <tbody className="crtCalendarTable-body">
            {
              weeks.map(
                (week, index) => (
                  <tr key={index}>
                    {week.map((day, index) => (
                      <td key={index}
                          className={`crtCalendarTable-cell${isAvailable(day.date) ? " is-available" : " is-unavailable"}`}
                      >
                        {
                          isAvailable(day.date)
                            ? (
                              <button
                                className="crtCalendarTable-dayLabel"
                                onClick={() => selectDate(day.date)}
                                type="button"
                              >
                                {day.desc}
                              </button>
                            )
                            : day.desc
                        }
                      </td>
                    ))}
                  </tr>
                ))
            }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <>
      <button
        className="crtPickupUpDate"
        type="button"
        onClick={() => openCalendar === id ? setOpenCalendar(null) : setOpenCalendar(id)}>
        {
          (state.selectedDate !== null && state.selectedDate !== 'null') ?
            DateTime.fromISO(JSON.parse(state.selectedDate)).toLocaleString()
            :
            <Translation id="add_date" />
        }
        <Icon name="plus" />
      </button>
      {openCalendar === id ? buildCalendarMarkup() : null}
    </>
  )
}

export default Calendar
