import React from 'react';
import classname from 'classnames';

const composedIcons = [
  'account',
  'delivery',
  'cb',
  'pit',
];

const Icon = ({ name, className }) =>  (
  <svg viewBox="0 0 100 100" className={classname(`crt-icon-${name}`, className)} aria-hidden="true">
    {composedIcons.find(iconName => iconName === name) ?
      <>
        <use className="primaryPath" xlinkHref={`#crt-${name}-primary`}></use>
        <use className="secondaryPath" xlinkHref={`#crt-${name}-secondary`}></use>
      </>
      :
      <use xlinkHref={`#crt-${name}`}></use>
    }
  </svg>
);

export default Icon;
