import { SEARCH_START_AT } from '../config/config';

export const hasFilter = (search) => (
  search.interests.length
  || search.audience
  || search.period.season !== null
)

export const hasKeywords = (search) => (
  search.query.trim().length >= SEARCH_START_AT
)

export const hasNoSearch = (search) => (
  !hasFilter(search) && !hasKeywords(search)
)
