import React, { useEffect, useRef } from 'react';
import Form from './Form/Form';
import Results from './Results';
import { useSelector } from 'react-redux';
import Translation from '../../common/Translation';

import Icon from '../../common/Icon'

const PopIn = ({onClose}) => {
  const popIn = useRef(null)
  const { currentPage } = useSelector(state => state.search)

  useEffect(() => {
    if (popIn && popIn.current) {
      popIn.current.scrollTop = 0
    }
  }, [currentPage, popIn]);

  return (
    <div className="crtSearchPopin" ref={popIn}>
      <div className="crtSearchPopin-top">
        <button type="button" className="crtSearchPopin-top-button" onClick={onClose}>
          <Translation id="close" />
          <Icon name="cross" />
          <span className="sr-only"><Translation id="close" /></span>
        </button>
      </div>

      <Form />

      <Results />
    </div>
  );
};

export default PopIn;
