import React, { useContext, useEffect, useState } from 'react';
import Algolia from '../../../search/services/Algolia';
import { useDispatch, useSelector } from 'react-redux';
import { setAudience } from '../../actions/searchAction';
import Translation, { TranslationContext } from '../../../common/Translation';
import { SegmentAdvanced } from '../../../search/helpers/SegmentHelper';
import Icon from '../../../common/Icon'

const AudienceFilter = () => {
  const [audiences, setAudiences] = useState([])
  const activeAudience = useSelector(state => state.search.audience)
  const dispatch = useDispatch();
  const { translationRegistry: translations } = useContext(TranslationContext)

  useEffect(() => {
    getAudiences()
  }, [])

  const getAudiences = async () => {
    const facets = await Algolia.getFacets('audienceSegments')

    const audienceSortOrder = ['17740', '17739', '6882', '11022', '11023', '24749']
    facets.sort((a, b) => audienceSortOrder.indexOf(a.value) - audienceSortOrder.indexOf(b.value))

    setAudiences(facets)
  }

  const onValueChange = (newValue) => {
    const newAudience = audiences.find(audience => audience.value === newValue)
    dispatch(setAudience(newAudience))
  }

  return (
    <div className="crtSearchFilter crtAudienceFilter">
      <span className="crtFilterLabel">
        <Translation id="audience" />
      </span>

      <div className="crtSelect-wpr">
        <select
          className="crtAudienceFilterSelector"
          name="audience"
          id="audience"
          onChange={event => onValueChange(event.target.value)}
          value={activeAudience ? activeAudience.value : 'all'}
        >
          <option value="all">
            {translations.all_audiences}
          </option>
          {
            audiences.map(audience => (
              <option value={audience.value} key={audience.value}>
                {SegmentAdvanced.getLabel(audience.value)}
              </option>
            ))
          }
        </select>
        <Icon name="down" />
      </div>
    </div>
  );
};

export default AudienceFilter;
