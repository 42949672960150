import React, { useEffect, useState } from 'react';
import SearchBtn from './SearchBtn'
import PopIn from './PopIn'
import { useDispatch } from 'react-redux';
import { resetSearch, setPage, setQuery } from '../actions/searchAction';

const Advanced = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const dispatch = useDispatch()
  const documentBody = document.body

  useEffect(() => {
    const url = new URL(window.location.href)
    const search = url.searchParams.get('search')
    const page = url.searchParams.get('page')

    if (search) {
      setIsOpen(true)
      dispatch(setQuery(search))

      if (page) {
        dispatch(setPage(page))
      }
    }
  }, [])

  const closePopin = () => {
    setIsOpen(false)
    history.replaceState(null, null, window.location.pathname)
    dispatch(resetSearch())
  }

  if (isOpen) {
    documentBody.classList.add('crtBodyHideScrollY')
  } else {
    documentBody.classList.remove('crtBodyHideScrollY')
  }

  return (
    <>
      <SearchBtn onClick={() => setIsOpen(true)}/>
      { isOpen && <PopIn onClose={closePopin} /> }
    </>
  )
}

export default Advanced
