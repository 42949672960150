import { RESET_RESULTS, SET_RESULTS } from '../../search-advanced/actions/resultsAction';

const initialState = {
  exhaustiveNbHits: true,
  hits: [],
  hitsPerPage: 20,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  params: null,
  processingTimeMS: null,
  query: null,
  isLoading: true,
}

const resultsReducer = (state = initialState, { type, payload }) => {
 switch (type) {
   case SET_RESULTS:
     return { ...payload }
   case RESET_RESULTS:
     return initialState
   default:
     return state;
 }
}

export default resultsReducer;
