class SegmentHelper {
  constructor(searchType) {
    const { segmentsData: { translations = {} } = {}} = window
    this.data = translations[searchType];
  }

  getLabel = (code) => {
    return this.data[code] ? this.data[code] : code
  }
}

export const SegmentAdvanced = new SegmentHelper('advancedSegments')
export const SegmentInspiration = new SegmentHelper('inspirationSegments')
