import React from 'react'
import Translation from '../../common/Translation'

const SearchBtn = ({ onClick }) => {

  return (
    <button
      type="button"
      role="button"
      tabIndex="0"
      aria-pressed={false}
      className="crtHeaderTool crtSearchTool"
      onClick={onClick}
    >
      <span className="crtSearchTool-label">
        <Translation id="btn_search_label" />
      </span>
      <svg viewBox="0 0 100 100" className="crt-icon-search">
        <use xlinkHref="#crt-search"/>
      </svg>
    </button>
  )
}

export default SearchBtn
