import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { addInterest, removeInterest } from '../../actions/searchAction';
import { SegmentAdvanced } from '../../../search/helpers/SegmentHelper';

const InterestButton = ({ value, isActive, disabled }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (isActive) {
      return dispatch(removeInterest(value))
    }

    return dispatch(addInterest(value))
  }

  return (
    <button
      type="button"
      role="button"
      tabIndex="0"
      aria-pressed={false}
      className={classNames('crtBtn', { 'active': isActive })}
      onClick={handleClick}
      disabled={disabled}
    >
      {SegmentAdvanced.getLabel(value)}
    </button>
  );
};

export default InterestButton;
