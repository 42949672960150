import React, { useContext, useCallback, Fragment } from 'react'
import Markdown from 'react-markdown'
import ReactDOMServer from 'react-dom/server';

export const TranslationContext = React.createContext({})

export const useTranslation = () => {
  const { translationRegistry } = useContext(TranslationContext)

  const translate = useCallback(({ id, values, domain, markdown = false}) => {
    const translation = domain ? (translationRegistry[domain] && translationRegistry[domain][id]) : translationRegistry[id]

    if (translation) {

      if (values) {
        const enriched = translation.split(/({[a-zA-Z0-9.-_]+})/).reduce(
          (acc, part, key) => [
            ...acc,
            /^{[a-zA-Z0-9.-_]+}$/.test(part) ? (
              <Fragment key={key}>{values[part.replace(/{([a-zA-Z0-9.-_]+)}/g, '$1')]}</Fragment>
            ) : (
              <Fragment key={key}>{part}</Fragment>
            )
          ],
          []
        )

        if (markdown) {

          return <Markdown>{ReactDOMServer.renderToStaticMarkup(enriched)}</Markdown>
        }

        return enriched
      }

      if (markdown) {

        return <Markdown>{translation}</Markdown>
      }

      return translation
    }

    return domain ? id : `[${id}]`
  }, [translationRegistry])

  return translate
}

const Translation = (props) => {
  const translate = useTranslation()

  return translate(props)
}

export default Translation
