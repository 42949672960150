import React from 'react';

const ResultsList = ({ results }) => {
  return (
    <>
      {
        results.hits.map(result => (
          <div className="crtTeaser-wpr" dangerouslySetInnerHTML={{__html: result.teaser}}
               key={result.objectID} />
        ))
      }
    </>
  );
};

export default ResultsList;
