export const SET_INTERESTS = 'SET_INTERESTS';
export const SET_AUDIENCE = 'SET_AUDIENCE';
export const SET_SEASON = 'SET_SEASON';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_QUERY = 'SET_QUERY';
export const SET_PAGE = 'SET_PAGE';

export const addInterest = (interest) => (dispatch, getState) => {
  const { interests } = getState().search;
  dispatch({
    type: SET_INTERESTS,
    payload: [...interests, interest],
  })
}

export const removeInterest = (interest) => (dispatch, getState) => {
  const { interests } = getState().search;
  dispatch({
    type: SET_INTERESTS,
    payload: [...interests.filter(f => f !== interest)],
  })
}

export const resetFilters = () => (dispatch) => {
  dispatch({ type: RESET_FILTERS, payload: [] })
}

export const resetSearch = () => (dispatch) => {
  dispatch({ type: RESET_SEARCH })
}

export const setAudience = (audience) => (dispatch) => {
  dispatch({ type: SET_AUDIENCE, payload: audience })
}

export const setPeriod = (startDate, endDate) => (dispatch) => {
  dispatch({
    type: SET_SEASON,
    payload: { startDate, endDate }
  })
}

export const setQuery = (query) => (dispatch) => {
  dispatch({ type: SET_QUERY, payload: query })
}

export const setPage = (page) => (dispatch, getState) => {
  const query = getState().search.query

  dispatch({ type: SET_PAGE, payload: page })
  history.replaceState(null, null, `${window.location.pathname}?search=${query}&page=${page}`);
}
