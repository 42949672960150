import React, { useContext } from 'react';
import Translation, { TranslationContext } from '../../../common/Translation';

const Pager = ({ page, nbPages, onPageChange }) => {
  const { translationRegistry: translations } = useContext(TranslationContext)

  const getPagerLinkArray = () => {
    let linkArray = [0];

    if (page > 3) {
      linkArray.push('ellipsis');
    }
    if (page < 3 ) {
      [1, 2, 3, 4].forEach(index => {
        if (nbPages > index && index !== nbPages - 1) {
          linkArray.push(index);
        }
      })
    } else if (nbPages - 1 - page < 3) {
      [4, 3, 2, 1].forEach(index => {
        if (nbPages > index) {
          linkArray.push(nbPages - 1 - index);
        }
      })
    } else {
      linkArray.push(page - 2);
      linkArray.push(page - 1);
      if (page !== nbPages - 1) {
        linkArray.push(page);
      }
      if (page + 1 !== nbPages - 1) {
        linkArray.push(page + 1);
      }
      if (page + 2 !== nbPages - 1) {
        linkArray.push(page + 2);
      }
    }
    if (nbPages - 1 - page > 3) {
      linkArray.push('ellipsis');
    }
    linkArray.push(nbPages - 1);

    return linkArray;
  }

  const onClick = (event, newPage) => {
    event.preventDefault();
    onPageChange(newPage);
  }

  return (
    <div className="crtInner">
      {
        nbPages && nbPages > 1
          ? (
            <nav className="crtPagination" role="navigation" aria-label="Pagination Navigation">
              <button className="crtPagination-btn" onClick={() => onPageChange(page - 1)}>
                <svg viewBox="0 0 100 100" className="crt-left"><use xlinkHref="#crt-left"/></svg>
                <span className="crtPagination-btnLabel">
                  <Translation id="previous" />
                </span>
              </button>

              <ol className="crtPagination-list">
                {
                  getPagerLinkArray().map((element, key) => {
                    return (
                      element === 'ellipsis'
                        ? <li key={key} className="crtPagination-item">…</li>
                        : <li
                          key={key}
                          className={`crtPagination-item ${element === page ? ' is-current' : ''}`}
                          aria-current={element === page}
                        >
                          <a
                            href="#"
                            aria-label={`${translations.goto} ${element}`}
                            className="crtPagination-link"
                            onClick={(event) => onClick(event, element)}
                            data-goto-page={element}
                          >
                            {element + 1}
                          </a>
                        </li>
                    )
                  })
                }
              </ol>

              <button className="crtPagination-btn" onClick={() => onPageChange(page + 1)}>
                <span className="crtPagination-btnLabel">
                  <Translation id="next" />
                </span>
                <svg viewBox="0 0 100 100" className="crt-right"><use xlinkHref="#crt-right"/></svg>
              </button>
            </nav>
          )
          : null
      }
    </div>
  );
};

export default React.memo(
  Pager,
  (prevProps, nextProps) => (
    JSON.stringify(prevProps) === JSON.stringify(nextProps)
  )
);
