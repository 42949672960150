import React, { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../../common/Translation';
import { useDispatch, useSelector } from 'react-redux';
import { setPeriod } from '../../actions/searchAction';
import { DateTime, Duration } from 'luxon';
import Calendar from '../Calendar';

export const CalendarsContext = React.createContext();
const now = DateTime.local();

const SeasonFilter = () => {
  const [closingStartDate, setClosingStartDate] = useState(now.plus(Duration.fromObject({days: 1})))
  const [openCalendar, setOpenCalendar] = useState(null)
  const { startDate, endDate } = useSelector(state => state.search.period)
  const { translationRegistry: translations } = useContext(TranslationContext)
  const dispatch = useDispatch()

  useEffect(() => {
    startDate
      ? setClosingStartDate(startDate.plus(Duration.fromObject({days: 1})))
      : setClosingStartDate(now.plus(Duration.fromObject({days: 1})));
  }, [startDate])

  return (
    <CalendarsContext.Provider value={[openCalendar, setOpenCalendar]}>
      <div className="crtSearchFilter crtSeasonFilter">
        {translations.arrival &&
          <span className="crtFilterLabel">
            {translations.arrival}
          </span>
        }

        <Calendar
          id="departure"
          value={startDate}
          openingDate={[{
            startDate: now.toString(),
            endDate: endDate
              ? endDate.minus(Duration.fromObject({days: 1})).toString()
              : now.plus(Duration.fromObject({years: 10})).toString(),
          }]}
          handleChange={date => dispatch(setPeriod(DateTime.fromJSDate(date), endDate))}
        />
      </div>

      <div className="crtSearchFilter crtSeasonFilter">
        {translations.departure &&
          <span className="crtFilterLabel">
            {translations.departure}
          </span>
        }

        <Calendar
          id="arrival"
          value={endDate}
          openingDate={[{
            startDate: closingStartDate.toString(),
            endDate: closingStartDate.plus(Duration.fromObject({years: 10})).toString(),
          }]}
          handleChange={date => dispatch(setPeriod(startDate, DateTime.fromJSDate(date)))}
        />
      </div>
    </CalendarsContext.Provider>
  );
};

export default SeasonFilter;
