import algoliasearch from 'algoliasearch';
import { apiKey, appId, indexName } from '../config/algolia';

const { locale = 'fr' } = {} = window.searchData

class AlgoliaService {
  constructor(locale) {
    const client = algoliasearch(appId, apiKey);
    this.index = client.initIndex(indexName + '__' + locale)
  }

  getFacets = async (facetName) => {
    if (facetName) {
      try {
        const response = await this.index.searchForFacetValues(facetName);
        const { facetHits } = response;

        return facetHits;
      } catch (e) {
        console.error(e);
      }
    }

    return [];
  }

  search = async (query, params) => {
    try {
      return await this.index.search(query, params)
    } catch (e) {
      console.error(e)
      return null;
    }
  }
}

const Algolia = new AlgoliaService(locale);

export default Algolia;
