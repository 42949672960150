import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { TranslationContext } from '../../../common/Translation';
import ResultTitleCount from './ResultTitleCount';

const ResultTitle = ({ results, type, searchQuery = null }) => {
  const { translationRegistry: translations } = useContext(TranslationContext)

  const getNoResultsMessage = (type, searchQuery) => {
    // Si type advanced ET que la searchQuery n'est pas vide => on affiche no_results_message SINON null
    // Sinon on est en recherche inspiration

    return 'advanced' === type
      ? searchQuery.length ? <ReactMarkdown source={translations.no_results_message.replace('{search_query}', searchQuery)} /> : null
      : <ReactMarkdown source={translations.no_results_inspiration_message} />
  }

  return (
    <>
      {
        results.nbHits > 0
          ? <ResultTitleCount results={results} classes="crtSearchAdvanced-results-title" />
          : (
            <div className="crtInner is-contrib crtSearchAdvanced-no-results-message">
              { getNoResultsMessage(type, searchQuery) }
            </div>
          )
      }
    </>
  );
};

export default ResultTitle;
